export enum CacheKeyEnum {
  Default = 'internal_vip',
  EndpointUserInfo = 'internal_vip_endpoint_user_info',
  EndpointPaymentMethods = 'internal_vip_endpoint_payment_methods',
  EndpointBonuses = 'internal_vip_endpoint_bonuses',
  EndpointFeeFreeTime = 'internal_vip_endpoint_fee_free_time',
  EndpointDepositLimits = 'internal_vip_endpoint_deposit_limits',
  EndpointDepositRollovers = 'internal_vip_endpoint_rollovers',
  EndpointDepositAccounts = 'internal_vip_endpoint_deposit_accounts',
}
