export interface GenericCatalogDto {
  id: number;
  name: string;
  description: string;
  status: number;
  created_at: Date;
  updated_at: Date;
}

export interface IAppSettingsDto {
  site: IAppSettingsSiteDto;
  logo: IAppSettingsLogoDto;
  theme: IAppSettingsThemeDto;
}
export const IEmptyAppSettingsDto: IAppSettingsDto = {
  site: { id: 0, name: '', url: '' },
  logo: { url: '' },
  theme: { url: '' },
};
export interface IAppSettingsSiteDto {
  id: number;
  name: string;
  url: string;
}
export interface IAppSettingsLogoDto {
  url: string;
}
export interface IAppSettingsThemeDto {
  url: string;
}

export interface ICacheRequest {
  cacheKey: string;
}
