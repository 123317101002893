import { IApprovePurchaseDto } from '../../models/ApprovePurchase/ApprovePurchase';
import { IBonus } from '../../models/Bonus/bonus';
import { emptyCashierDialog, ICashierDialog } from '../../models/cashier-transaction';
import { FeeFreeTime } from '../../models/FeeFreeTime';
import { IPaymentMethods } from '../../models/paymentMethods';
import { IPlayerTransactionLimitDetails } from '../../models/player-transaction-limit-details';
import { IInsertTransactionResponse } from '../../models/Transactions/transaction';
import { createAndMergeSliceReducer } from '../../utils/redux/create-and-merge-slice-reducer';

const sliceName = 'cashierTransactionSlice';

export interface CashierTransactionSliceType {
  isLoading: boolean;
  isLoadingCreateTransaction: boolean;
  isLoadingValidateUseWithdrawalCode: boolean;
  isLoadingPendingTransactionApprovePurchase: boolean;
  isSaving: boolean;
  isWelcomeBonus: boolean;
  rollover?: any[];
  type: number | null;
  method: any | null;
  bonus?: any[] | null;
  player_number: string | null;
  account?: string | null;
  amount: number | null;
  name?: string | null;
  fingerprint?: string | null;
  ip?: string | null;
  paymentMethods: IPaymentMethods[];
  availableBonuses: any[] | null;
  playerProfileSettingsPaymentMethodBonuses: IBonus[];
  transaction: IInsertTransactionResponse | null;
  isTransactionSuccess: boolean;
  bankReference: any | null;
  transactionStatus: any | null;
  cbioAllowed: any | null;
  smsCodeCheck: any | null;
  feeFreeTime: FeeFreeTime[];
  playerBanks?: any[] | null;
  institutions?: any[] | null;
  institution?: any | null;
  mfa?: any[] | null;
  selectableBanks?: any[] | null;
  wdHoldAmount: any | null;
  accounts: string[];
  cashierDialog: ICashierDialog;
  transactionLimitDetails?: IPlayerTransactionLimitDetails;
  useWithdrawalVerificationCode: boolean;
  transaction_approve_purchase: IApprovePurchaseDto[] | null;
  is_approve_purchase_success: boolean;
}

export const initialState: CashierTransactionSliceType = {
  account: null,
  accounts: [],
  amount: null,
  availableBonuses: [],
  bankReference: null,
  bonus: [],
  cashierDialog: emptyCashierDialog,
  cbioAllowed: null,
  feeFreeTime: [],
  fingerprint: null,
  ip: null,
  isLoading: false,
  isLoadingCreateTransaction: false,
  isLoadingPendingTransactionApprovePurchase: false,
  isLoadingValidateUseWithdrawalCode: false,
  isSaving: false,
  isWelcomeBonus: false,
  isTransactionSuccess: false,
  method: null,
  name: '',
  paymentMethods: [],
  player_number: null,
  playerProfileSettingsPaymentMethodBonuses: [],
  rollover: [],
  smsCodeCheck: null,
  transaction: null,
  transactionLimitDetails: undefined,
  transactionStatus: '',
  type: null,
  wdHoldAmount: null,
  useWithdrawalVerificationCode: true,
  transaction_approve_purchase: null,
  is_approve_purchase_success: false,
};

export const cashierTransactionReducer = createAndMergeSliceReducer(sliceName, initialState);
