import { CacheKeyEnum } from '../enum/cache/CacheKeyEnum';

export const GetInternalKey = (key: string) => {
  try {
    let username = localStorage.getItem('username');

    if (!username) {
      const user = localStorage.getItem('user');
      if (user) {
        const userObj = JSON.parse(user);
        if (userObj && userObj.username) {
          localStorage.setItem('username', userObj.username);
          username = userObj.username;
        }
      }
    }

    if (key.includes(CacheKeyEnum.Default) && username) {
      return `${key}_${username}`;
    }
  } catch (e) {
    console.log('GetInternalKey', e);
  }

  return key;
};

export const GetDataByKey: any = (key: string) => {
  try {
    const localKey = GetInternalKey(key);

    const localStorageData = localStorage.getItem(localKey);
    if (localStorageData) {
      const data = JSON.parse(localStorageData);

      if (data.expiration) {
        if (data.expiration < new Date().getTime()) {
          localStorage.removeItem(localKey);
          return null;
        }

        return data.value;
      } else {
        return null;
      }
    }
  } catch (e) {
    console.log('GetDataByKey', e);
  }

  return null;
};

export const SetDataByKey: any = (key: string, value: any, expiration: number | null) => {
  try {
    const expirationTime = (expiration ?? 30) * 1000 + new Date().getTime();

    const localKey = GetInternalKey(key);

    const localStorageData = localStorage.getItem(localKey);
    if (localStorageData) {
      localStorage.removeItem(localKey);
    }

    localStorage.setItem(localKey, JSON.stringify({ value, expiration: expirationTime }));
    return true;
  } catch (e) {
    console.log('SetDataByKey', e);
  }

  return false;
};

export const RemoveDataByKey: any = (key: string) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    console.log('RemoveDataByKey', e);
  }
  return false;
};

export const ClearAllKeysByKey: any = (externalKey: string) => {
  try {
    const keys = Object.keys(localStorage);
    keys.forEach(key => {
      if (key.includes(externalKey)) {
        localStorage.removeItem(key);
      }
    });
    return true;
  } catch (e) {
    console.log('ClearAllKeysByKey', e);
  }
  return false;
};
